import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ticketPriority } from '../../models/Ticket';

const PriorityIcon = ({ priority, name }) => {
  const index = ticketPriority.findIndex(p => parseInt(p.id, 10) === priority);
  const color = ticketPriority[index].color;

  let icon;
  if (priority === 0){
    icon = (
      <Stack direction='row' alignItems='center' gap={0} ml={-0.75} mb={0.5}>
        <PriorityHighIcon sx={{ color: color, fontSize: 17 }} />
        <PriorityHighIcon sx={{ color: color, fontSize: 17 }} />
        <PriorityHighIcon sx={{ color: color, fontSize: 17 }} />
        <Typography variant="caption" sx={{ color: color, fontSize: 12 }} >{ticketPriority[index][name]}</Typography>
      </Stack>
    )
  } else if (priority === 1){
    icon = (
      <Stack direction='row' alignItems='center' gap={0} ml={-0.75} mb={0.5}>
        <PriorityHighIcon sx={{ color: color, fontSize: 17 }} />
        <PriorityHighIcon sx={{ color: color, fontSize: 17 }} />
        <Typography variant="caption" sx={{ color: color, fontSize: 12 }} >{ticketPriority[index][name]}</Typography>
      </Stack>
    )
  } else if (priority === 2){
    icon = (
      <Stack direction='row' alignItems='center' gap={0} ml={-0.75} mb={0.5}>
        <PriorityHighIcon sx={{ color: color, fontSize: 17 }} />
        <Typography variant="caption" sx={{ color: color, fontSize: 12 }} >{ticketPriority[index][name]}</Typography>
      </Stack>
    )
  } else if (priority === 3){
    icon = (
      <Stack direction='row' alignItems='center' gap={0.5} mb={0.5}>
       <MoreHorizIcon sx={{ color: color, fontSize: 17 }} />
        <Typography variant="caption" sx={{ color: color, fontSize: 12 }}  >{ticketPriority[index][name]}</Typography>
      </Stack>
    )
  } else {
    icon = (
      <Stack direction='row' alignItems='center' gap={0.5} mb={0.5}>
        <DoNotDisturbOnIcon sx={{ color: color, fontSize: 17 }} />
        <Typography variant="caption" sx={{ color: color, fontSize: 12 }} >
          {ticketPriority[index][name]}
        </Typography>
      </Stack>
    )
  };

  return (
    <>
      {icon}
    </>
  )
};

export default PriorityIcon;