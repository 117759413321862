import { Typography, Grid } from '@mui/material';
import { useDocument } from '../../hooks/useDocument';
import EmbeddedGoogleMaps from '../EmbeddedGoogleMaps/EmbeddedGoogleMaps';

const LocationDetails = (props) => {
  const { formValues } = props;
  const { location } = formValues;

  const { document: locationData } = useDocument('locations', location);

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom >
        Location
      </Typography>
      {locationData && (
        <>
        <Typography gutterBottom>{`Location: ${locationData.details.name} - ${locationData.address.streetAddress1} ${locationData.address.city}, ${locationData.address.state}`}</Typography>
        <EmbeddedGoogleMaps 
          h='225' 
          w='425' 
          streetAddress={locationData.address.streetAddress1}
          city={locationData.address.city}
          state={locationData.address.state}
        />
        </>
      )}    
    </Grid>
  );
}

export default LocationDetails;