import {
  Typography,
  Grid,
  Divider,
  Stack,
} from '@mui/material'

import StatusIcon from '../Icons/StatusIcon';
import CategoryIcon from '../Icons/CategoryIcon';
import PriorityIcon from '../Icons/PriorityIcon';
import StateIcon from '../Icons/StateIcon';
import { useDocument } from '../../hooks/useDocument';

const TicketCard = ({ ticketData }) => {
  const { document: location } = useDocument('locations', ticketData.location.id);

  const fullDate = ticketData.metadata.updated.timestamp.toDate().toLocaleString('en-US', {
    timeStyle: 'short',
    dateStyle: 'short',
  }).split(', ');

  return <>
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={4}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="#0F6EB1" gutterBottom>
          {ticketData.num}
        </Typography>
        <PriorityIcon priority={ticketData.details.priority} name='shortName' />
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }} >
          {location && location.details.name}
        </Typography>
        <Typography sx={{ fontSize: 12 }} gutterBottom>
          {location && location.address.streetAddress1 + ' ' + location.address.city + ', ' + location.address.state }
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {ticketData.details.category.map((cat, ind) => <CategoryIcon key={ind} category={cat} />)}
        <Typography sx={{ fontSize: 12, fontWeight: "bold" }} >
          Problem Area:
        </Typography>
        <Typography sx={{ fontSize: 12} } gutterBottom >
          { ticketData.details.area }
        </Typography>
        <Divider />
        <Typography sx={{ fontSize: 12, fontWeight: "bold" }} >
          Description:
        </Typography>
        <Typography sx={{ fontSize: 12} } >
          { ticketData.details.description }
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography sx={{ fontSize: 12 }} pb={1} textAlign="center" >
          {`${fullDate[0]}, ${fullDate[1]}`}
        </Typography>
        <Stack spacing={1} alignItems="center">
          <StatusIcon status={ticketData.status.status} />
          <Stack direction="row">
            <StateIcon state={ticketData.status.state[0]} />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  </>
};

export default TicketCard;