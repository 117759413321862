
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

const AttachmentItem = (props) => {
  const { attachment, deleteAttachment, ableToDownload, downloadAttachment } = props

  const actionIcon = ableToDownload ? (
    <IconButton edge="end" aria-label="delete" onClick={downloadAttachment} >
      <DownloadIcon />
    </IconButton>
  ) : (
    <IconButton edge="end" aria-label="delete" onClick={() => deleteAttachment(attachment.name)} >
      <DeleteIcon />
    </IconButton>
  )

  return (
    <ListItem
      secondaryAction={actionIcon}
    >
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={attachment.name}
      />
    </ListItem>
  )

}

export default AttachmentItem;