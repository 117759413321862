import { useNavigate } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';

export const MainListItems = () => {
  const navigate = useNavigate();
  
  return <>
    <ListItemButton onClick={() => navigate('/new-ticket')} >
      <ListItemIcon >
        <AddIcon sx={{ color: '#0F6EB1' }} />
      </ListItemIcon>
      <ListItemText primary="New Ticket" sx={{ color: '#0F6EB1' }} />
    </ListItemButton>
  </>
};

export const SecondaryListItems = () => {
  const navigate = useNavigate();

  return <>
    <ListItemButton onClick={() => navigate('/')}>
      <ListItemIcon >
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
  </>
};