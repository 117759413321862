import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { OutlinedInput, FormControl, InputLabel, InputAdornment, FormHelperText } from '@mui/material';

const AmountField = (props) => {
  const {label, name, errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        label={label}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        type="number"
        {...rest}
        {...field}
      />
      {_renderHelperText()}
    </FormControl>
  );
}

export default AmountField;