import { ticketState } from '../../models/Ticket';
import Chip from '@mui/material/Chip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';


const StateIcon = ({ state }) => {
  let icon;

  switch (state){
    case 0:
      icon = <Chip color='success' variant='outlined' icon={<EventAvailableIcon />} label={ticketState[state].name} size='small' />
      break;

    case 1:
      icon = <Chip color='success' variant='outlined' icon={<LocalShippingIcon />} label={ticketState[state].name} size='small' /> 
      break;

    case 2:
      icon = <Chip color='info' variant='outlined' icon={<EventBusyIcon />} label={ticketState[state].name} size='small' /> 
      break;

    case 3:
      icon = <Chip color='success' variant='outlined'icon={<LocationOnIcon />} label={ticketState[state].name} size='small' /> 
      break;

    case 4:
      icon = <Chip color='warning' variant='outlined' icon={<ReceiptIcon />} label={ticketState[state].name} size='small' /> 
      break;

    case 6:
      icon = <Chip color='info' variant='outlined' icon={<AssignmentIndIcon />} label={ticketState[state].name} size='small' /> 
      break;
   
    case 7:
      icon = <Chip color='success' variant='outlined' icon={<AssignmentIndIcon />} label={ticketState[state].name} size='small' /> 
      break;
   
    case 8:
      icon = <Chip color='warning' variant='outlined' icon={<ReceiptIcon />} label={ticketState[state].name} size='small' /> 
      break;
    
    case 9:
      icon = <Chip color='error' variant='outlined' icon={<AttachMoneyIcon />} label={ticketState[state].name} size='small' /> 
      break;

    default:
      icon = <></>
      break;
  }

  return icon;
}

export default StateIcon;