import { at } from 'lodash';
import { useField } from 'formik';
import {
 FormControl,
 ToggleButtonGroup,
 ToggleButton, 
 FormHelperText,
} from '@mui/material';

const CheckboxField = (props) => {
  const { label, data, name, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { value: selectedValue } = field;
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const _onChange = (e, newValue) => {
    setValue(newValue);
  }

  return (
    <FormControl {...rest}>
      <ToggleButtonGroup
        color="primary"
        name={name}
        value={selectedValue}
        onChange={_onChange}
        exclusive
      >
        {data.map((item) => <ToggleButton value={item.id} key={item.name}>{item.name}</ToggleButton>)}
      </ToggleButtonGroup>
      {_renderHelperText()}
    </FormControl>
  );
}

export default CheckboxField;