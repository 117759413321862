import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Title from '../UI/Title';
import { useDocument } from '../../hooks/useDocument';
import EmbeddedGoogleMaps from '../EmbeddedGoogleMaps/EmbeddedGoogleMaps';
import CategoryIcon from '../Icons/CategoryIcon';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ticketCategory } from '../../models/Ticket';
import PriorityIcon from '../Icons/PriorityIcon';
import StatusIcon from '../Icons/StatusIcon';
import StateIcon from '../Icons/StateIcon';

const dollarUSLocale = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const DetailedTicket = ({ ticket }) => {
  const { document: location } = useDocument('locations', ticket.location.id);

  const formattedNte = dollarUSLocale.format(ticket.details.nte)

  return <>
  <Typography variant='overline'>Ticket Details</Typography>
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Title>{ticket.num}</Title>
        {location && (
          <>
            <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>{location.details.name}</Typography>
            <Typography variant='caption' mb={1} >{`${location.address.streetAddress1} ${location.address.city}, ${location.address.state}`}</Typography>
            <EmbeddedGoogleMaps 
              h='225' 
              w='425' 
              streetAddress={location.address.streetAddress1}
              city={location.address.city}
              state={location.address.state}
            />
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid container item>
          <Grid item xs={6}>
            <StatusIcon status={ticket.status.status} />
          </Grid>
          <Grid item xs={6} sx={{ margin: 'auto' }}>
            <StateIcon state={ticket.status.state[0]} />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
        <Grid container item>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom >
              Priority:
            </Typography>
            <PriorityIcon priority={ticket.details.priority} name='name' />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom >
              NTE:
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{formattedNte}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
        <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom  >
          Category:
        </Typography>
        <Stack direction="row" spacing={1} mb={1} sx={{ flexWrap: 'wrap' }}>
          {ticket.details.category.map((cat, ind) => {
            return <Chip key={ind} icon={<CategoryIcon category={cat} />} label={ticketCategory[cat - 1].name} size='small' sx={{ marginBottom: 1 }} />
          })}
        </Stack>
        <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
        <Typography sx={{ fontSize: 12, fontWeight: "bold" }} >
          Problem Area:
        </Typography>
        <Typography sx={{ fontSize: 12} } gutterBottom >
          { ticket.details.area }
        </Typography>
        <Typography sx={{ fontSize: 12, fontWeight: "bold" }} >
          Description:
        </Typography>
        <Typography sx={{ fontSize: 12} } >
          { ticket.details.description }
        </Typography>
      </Grid>
    </Grid>
    
  </Paper>
  </>
};

export default DetailedTicket;