import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  Toolbar,
  Tooltip,
} from '@mui/material';
import Title from '../UI/Title';
import FilterBar from '../FilterBar/FilterBar';
import TicketCard from '../TicketCard/TicketCard';
import SearchField from '../FilterBar/SearchField';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

import { useCollection } from '../../hooks/useCollection';
import { useAuthContext } from '../../hooks/useAuthContext';
import { userType } from '../../models/Ticket';

const defaultFilters =  {
  status: null,
  priority: null,
  type: null,
  search: '',
}

const EnhancedTableToolbar = ({ filters, setFilters }) => {
  const [filterBarVisible, setFilterBarVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  const filterBySearch = (value) => {
    setFilters(prevState => {
      return { ...prevState, search: value }
    })
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Title
        sx={{ flex: '1 1 100%' }}
        component='div'
      >
        Service Tickets
      </Title>
      {filterBarVisible && (
        <FilterBar filters={filters} setFilters={setFilters} />
      )}
      {searchVisible && (
        <SearchField sx={{ width: 250 }} value={filters.search} setValue={filterBySearch} placeholder='Search By Ticket No.' />
      )}
      {!filterBarVisible && <Tooltip title="Search">
        <IconButton onClick={() => setSearchVisible(prevState => !prevState)} >
          <SearchIcon />
        </IconButton>
      </Tooltip>}
      {!searchVisible && <Tooltip title="Filter list">
        <IconButton onClick={() => setFilterBarVisible(prevState => !prevState)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>}
    </Toolbar>
  );
};

const Row = ({ row }) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow hover  onClick={() => {navigate(`/tickets/${row.id}`)}}>
        <TableCell scope="row" colSpan={6}>
          <TicketCard ticketData={row} />
        </TableCell>
        <TableCell>
          <IconButton
            size="small"
            component={Link} 
            to={`/ticket-details/${row.id}`}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

const TicketList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [filterValues, setFilterValues] = useState(defaultFilters);

  const { user } = useAuthContext();
  let ticketCompanyFilter = ['company.id', '==', user.company.id] // for LBRM 
  // let ticketCompanyFilter = ['client.id', '==', user.company.id]
  // if (user.type === userType.ADMIN) {
  //   ticketCompanyFilter = ['company.id', '==', user.company.id]
  // }
  const { documents: tickets } = useCollection('tickets', ticketCompanyFilter, ["metadata.updated.timestamp", "desc"]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let filteredTickets;
  if (tickets){
    filteredTickets = [...tickets];

    if (filterValues.priority){
      filteredTickets = filteredTickets.filter((ticket) => {
        return ticket.details.priority === parseInt(filterValues.priority.id, 10)
      })
    }

    if (filterValues.status){
      filteredTickets = filteredTickets.filter((ticket) => {
        return ticket.status.status === filterValues.status.id
      })
    }

    if (filterValues.type){
      filteredTickets = filteredTickets.filter((ticket) => {
        return ticket.details.type === filterValues.type.id
      })
    }

    if (filterValues.search){
      filteredTickets = filteredTickets.filter((ticket) => {
        return ticket.num.includes(filterValues.search)
      })
    }
  }

  return (
    <>
    <EnhancedTableToolbar filters={filterValues} setFilters={setFilterValues} />
    <TableContainer sx={{ backgroundColor: '#FAFAFA' }} component={Paper}>
      <Table>
        <TableBody>
          {tickets && filteredTickets.length === 0 && (
            <TableRow>
              <TableCell component="th" scope="row" align="center" colSpan={6}>
                No Tickets Have Been Found
              </TableCell>
            </TableRow>
          )}
          {tickets && filteredTickets.length > 0 && (
            filteredTickets
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row key={row.id} row={row} />
              ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={tickets ? filteredTickets.length : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </>
  );
}

export default TicketList;