import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const VisitStatusIcon = ({ status }) => {
  switch(status){
    // assigned
    case 0:
      return <AccountBoxIcon />
    
    // accepted
    case 1:
      return <AccountBoxIcon color="success" />

    // dispatched
    case 2:
      return <LocalShippingIcon color="warning" />

    // on site
    case 3:
      return <PersonPinCircleIcon color="success" />
    
    // completed
    case 5:
      return <CheckCircleOutlineIcon color="success" />

    // rejected
    case 6:
      return <PersonOffIcon  />

    // cancelled
    case 7:
      return <HighlightOffIcon color="error" />

    default:
      return
  }
}

export default VisitStatusIcon;