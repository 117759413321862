import { useEffect, useState } from 'react';
import { auth, db } from '../firebase/config';
import {
  signInWithEmailAndPassword, 
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  RecaptchaVerifier,
  signInWithPhoneNumber, 
} from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { useAuthContext } from './useAuthContext';

let smsConfirmationResult;

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: 'http://localhost:3000/confirm/email',
  url: 'https://servnology.web.app/confirm/email',
  // This must be true.
  handleCodeInApp: true,
};

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [ error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const setUpRecaptcha = (buttonId) => {
    window.recaptchaVerifier = new RecaptchaVerifier(buttonId, {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber
        // console.log('callback: ', response);
      }
    }, auth);
  };

  const loginWithEmailAndPassword = async (email, password) => {
    setError(null);
    setIsPending(true);

    // sign user in
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      let userData;
      let ref = doc(db, 'users', res.user.uid);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()){
        userData = { ...docSnap.data() };
      } 

      const userPayload = { ...res.user, ...userData }

      dispatch({ type: 'LOGIN', payload: userPayload });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled){
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  }

  const loginWithPhoneNumber = async (phoneNumber) => {
    setError(null);
    setIsPending(true);

    const appVerifier = window.recaptchaVerifier;
    
    try{
      smsConfirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch(err){
      if (!isCancelled){
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  }

  const confirmPhoneSignIn = async (confirmationCode) => {
    setError(null);
    setIsPending(true);

    // sign user in
    try {
      const res = await smsConfirmationResult.confirm(confirmationCode);

      let userData;
      let ref = doc(db, 'users', res.user.uid);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()){
        userData = { ...docSnap.data() };
      } 
      
      const userPayload = { ...res.user, ...userData }

      dispatch({ type: 'LOGIN', payload: userPayload });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled){
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  }

  const loginWithEmailLink = async (userEmail) => {
    setError(null);
    setIsPending(true);

    // sign user in
    try {
      if(isSignInWithEmailLink(auth, window.location.href)){
        const res = await signInWithEmailLink(auth, userEmail, window.location.href);
        
        let userData;
        let ref = doc(db, 'users', res.user.uid);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()){
          userData = { ...docSnap.data() };
        } 
        
        const userPayload = { ...res.user, ...userData }

        dispatch({ type: 'LOGIN', payload: userPayload });
      }

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled){
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  }

  const sendEmailLink = async (email) => {
    setError(null);
    setIsPending(true);

    // sign user in
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled){
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  }

  // clean up
  useEffect(() => {
    setIsCancelled(false);

    return () => setIsCancelled(true);
  }, []);

  return {
    setUpRecaptcha, 
    loginWithEmailAndPassword,
    loginWithPhoneNumber,
    confirmPhoneSignIn,
    sendEmailLink,
    loginWithEmailLink, 
    error, 
    isPending };
}