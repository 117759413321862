import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { InputField, ToggleButtonField, SelectField, AmountField } from '../FormFields/index';
import { 
  ticketTypes, 
  ticketCategory, 
  ticketPriority 
} from '../../models/Ticket';

const TicketDetailsForm = (props) => {
  const {
    formField: { type, area, description, nte, category, priority }
  } = props;

  const { values: formValues } = useFormikContext();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Ticket Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <ToggleButtonField 
            name={type.name}
            data={ticketTypes}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <SelectField
            name={priority.name}
            label={priority.label}
            data={ticketPriority}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <SelectField
            name={category.name}
            label={category.label}
            data={ticketCategory}
            multiple
            fullWidth
          />
        </Grid>
       {formValues.type === 1 && ( 
        <Grid item xs={12} sm={7}>
          <AmountField
            name={nte.name}
            label={nte.label}
          />
        </Grid>
        )}
        <Grid item xs={12} md={7}>
          <InputField
            name={area.name}
            label={area.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={11}>
          <InputField
            name={description.name}
            label={description.label}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default TicketDetailsForm;