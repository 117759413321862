import RoofingIcon from '@mui/icons-material/Roofing';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import WindowIcon from '@mui/icons-material/Window';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ShowerIcon from '@mui/icons-material/Shower';
import HvacIcon from '@mui/icons-material/Hvac';
import AttachmentIcon from '@mui/icons-material/Attachment';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GrassIcon from '@mui/icons-material/Grass';
import RollerShadesIcon from '@mui/icons-material/RollerShades';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import HardwareIcon from '@mui/icons-material/Hardware';
import GridOnIcon from '@mui/icons-material/GridOn';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ConstructionIcon from '@mui/icons-material/Construction';

const CategoryIcon = ({ category }) => {
  switch (category){
    case 1:
      return <PlumbingIcon fontSize="small" color="disabled" />
    
    case 2:
      return <FormatColorFillIcon fontSize="small" color="disabled" />

    case 3:
      return <RoofingIcon fontSize="small" color="disabled" />

    case 4:
      return <MeetingRoomIcon fontSize="small" color="disabled" />

    case 5:
      return <RollerShadesIcon fontSize="small" color="disabled" />

    case 6:
      return <ConstructionIcon fontSize="small" color="disabled" />
    
    case 7:
      return <FormatPaintIcon fontSize="small" color="disabled" />

    case 8:
      return <CarpenterIcon fontSize="small" color="disabled" />

    case 9:
      return <WindowIcon fontSize="small" color="disabled" />

    case 10:
      return <ElectricalServicesIcon fontSize="small" color="disabled" />

    case 11:
      return <ShowerIcon fontSize="small" color="disabled" />

    case 12:
      return <HardwareIcon fontSize="small" color="disabled" />

    case 13:
      return <GridOnIcon fontSize="small" color="disabled" />

    case 14:
      return <HvacIcon fontSize="small" color="disabled" />

    case 15:
      return <AttachmentIcon fontSize="small" color="disabled" />

    case 16:
      return <LightbulbIcon fontSize="small" color="disabled" />

    case 17:
      return <GrassIcon fontSize="small" color="disabled" />

    default:
      return
  }
}

export default CategoryIcon;