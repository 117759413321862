import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0F6EB1',
    }, secondary: {
      main: '#58585A',
    },
  },
});

const InputField = (props) => {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <ThemeProvider theme={theme} >
    <TextField
      type="text"
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
    </ThemeProvider>
  );
}

export default InputField;