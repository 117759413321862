import Typography from '@mui/material/Typography';
import { useCollection } from '../../hooks/useCollection';
import Note from './Note';
import { useEffect, useState } from 'react';

const TicketNotes = ({ ticket }) => {
  const { documents: noteList } = useCollection('ticket_notes', ['ticket.id', '==', ticket])

  const [notes, setNotes] = useState([])

  useEffect(() => {
    // public notes
    const fileteredNotes = noteList ? noteList.filter(noteItem => noteItem.visibility === 2) : []
    setNotes(fileteredNotes)
  }, [noteList])

  return <>
    <Typography variant='overline'>Notes</Typography>
      {notes && notes.length === 0 && 
        <Typography variant='subtitle2' textAlign='center'>No Notes Currently</Typography>
      }
      {notes && notes.length > 0 && 
        notes.map((note, ind) => (
          <Note key={`note-${ticket}-${ind}`} ticketNote={note} />
        ))
      }
  </>
}

export default TicketNotes;