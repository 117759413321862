const createTicketFormModel = {
  formId: 'createTicketForm',
  formField: {
    location: {
      name: 'location',
      label: 'Location',
      requiredErrorMsg: 'Location is required',
    },
    area: {
      name: 'area',
      label: 'Service Area',
      requiredErrorMsg: 'Service Area is required',
    },
    description: {
      name: 'description',
      label: 'Service Description',
      requiredErrorMsg: 'Service Description is required'
    },
    nte: {
      name: 'nte',
      label: 'NTE',
      invalidErrorMsg: 'Minimum NTE is $150.00'
    },
    category: {
      name: 'category',
      label: 'Categories',
      requiredErrorMsg: 'Please select at least one category',
      min: 1,
    },
    priority: {
      name: 'priority',
      label: 'Priority',
      requiredErrorMsg: 'A priority is required'
    },
    type: {
      name: 'type',
      label: 'Ticket Type',
      requiredErrorMsg: 'A ticket type is required',
    },
    attachments: {
      name: 'attachments',
      label: 'Upload Attachments'
    }
  }
};

export default createTicketFormModel;