import { createContext, useReducer, useEffect } from 'react';
import { auth, db } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch(action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }

    case 'LOGOUT':
      return { ...state, user: null }

    case 'AUTH_IS_READY':
      return { ...state, user: action.payload, authIsReady: true }

    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      let userData;
      if (user){
        let ref = doc(db, 'users', user.uid);
        const docSnap = await getDoc(ref);

        //set user data
        if (docSnap.exists()){
          userData = { ...user, ...docSnap.data() };

          // set company data
          let companyRef = doc(db, 'companies', userData.company.id);
          const companyDoc = await getDoc(companyRef)

          if (companyDoc.exists()) {
            userData = { 
              ...userData,
              company: {
                ...companyDoc.data()
              }
            }
          }
        }

      } else {
        userData = null;
      }
      dispatch({ type: 'AUTH_IS_READY', payload: userData })
      unsubscribe();
    });
  }, []);

  return(
    <AuthContext.Provider value={{ ...state, dispatch }}>
      { children }
    </AuthContext.Provider>
  )
}