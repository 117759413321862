import { useEffect, useState, useRef } from 'react';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

export const useDocument = (c, _d) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);

  const d = useRef(_d).current;

  useEffect(() => {
    let ref = doc(db, c, d);

    getDoc(ref)
    .then((snapshot) => {
      if (snapshot.exists()){
        setDocument({ ...snapshot.data(), id: snapshot.id })
      } else {
        setError('Cound not fetch data');
      }
    })
  }, [c, d])


  return { document, error };
};