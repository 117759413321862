import { useFormikContext } from 'formik';
import { Typography, Grid } from '@mui/material';
import LocationDetails from '../TicketDetails/LocationDetails';
import TicketDetails from '../TicketDetails/TicketDetails';

const ReviewTicket = () => {
  const { values: formValues } = useFormikContext();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Review Ticket
      </Typography>
      <Grid container spacing={3}>
        <LocationDetails formValues={formValues} />
        <TicketDetails formValues={formValues} />
      </Grid>
    </>
  );
}

export default ReviewTicket;