import createTicketFormModel from './TicketFormModel';
const {
  formField: {
    location,
    area,
    description,
    nte,
    category,
    priority,
    type,
    attachments
  }
} = createTicketFormModel;

const formInitialValues = {
  [location.name]: '',
  [area.name]: '',
  [description.name]: '',
  [nte.name]: '',
  [category.name]: [],
  [priority.name]: '',
  [type.name]: 1,
  [attachments.name] : '',
};

export default formInitialValues;