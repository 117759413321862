
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
  LoginPage,
  DashboardPage,
  ConfirmPage,
  NewTicketPage,
  TicketDetailsPage,
} from '../../pages/index';
import Layout from '../Layout/Layout';
import { ProtectedRoute, ProtectedRouteForLogin } from '../ProtectedRoute/ProtectedRoute';

const App = () => {
  const { authIsReady } = useAuthContext();
  
  return (
    <div>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route 
              path='/login' 
              element={
                <ProtectedRouteForLogin>
                  <LoginPage />
                </ProtectedRouteForLogin>
              } 
            />
            <Route 
              path='confirm/:type' 
              element={
                <ProtectedRouteForLogin>
                  <ConfirmPage />
                </ProtectedRouteForLogin>
              } 
            />
            <Route 
              path='/' 
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute> 
              }
            >
              <Route index element={<DashboardPage />} />
              <Route path='/tickets/:ticketId' element={<TicketDetailsPage />} />
              <Route path='/new-ticket' element={<NewTicketPage />} />
            </Route> 
          </Routes>
        </BrowserRouter>
      )}
   </div>
  );
}

export default App;
