import FilterAutocomplete from './FilterAutocomplete';
import {
  ticketTypes,
  ticketPriority,
  ticketStatus,
} from '../../models/Ticket';

const FilterBar = ({ filters, setFilters }) => {
  const {
    status,
    type,
    priority
  } = filters;

  const filterByStatus = (value) => {
    setFilters(prevState => {return { ...prevState, status: value }})
  }

  const filterByPriority = (value) => {
    setFilters(prevState => {return { ...prevState, priority: value }})
  }

  const filterByType = (value) => {
    setFilters(prevState => {return { ...prevState, type: value }})
  }

  return (
    <>
      <FilterAutocomplete label='Status' options={ticketStatus} value={status} setValue={filterByStatus} />
      <FilterAutocomplete label='Priority' options={ticketPriority} value={priority} setValue={filterByPriority} />
      <FilterAutocomplete label='Ticket Type' options={ticketTypes} value={type} setValue={filterByType} />
    </>
  )
};

export default FilterBar;