import TextField from '@mui/material/TextField';

const SearchField = (props) => {
  const {
    label,
    placeholder,
    value,
    setValue,
  } = props;

  return (
    <TextField 
      size='small'
      label={label}
      variant='outlined'
      value={value}
      onChange={(event) => setValue(event.target.value)}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default SearchField;