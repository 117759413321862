import { useEffect, useState, useRef } from 'react';
import { db } from '../firebase/config';
import { collection, onSnapshot, query, where, orderBy, } from 'firebase/firestore';

export const useCollection = (c, _q, _o) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const q = useRef(_q).current;
  const o = useRef(_o).current;

  useEffect(() => {
    setLoading(true);
    let ref = collection(db, c);

    if (q && o){
      ref = query(ref, where(...q), orderBy(...o));
    } else if (q){
      ref = query(ref, where(...q));
    } else if (o){
      ref = query(ref, orderBy(...o));
    }

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      let results = [];
      snapshot.docs.forEach(doc => {
        results.push({ ...doc.data(), id: doc.id });
      })

      setDocuments(results);
      setError(null);
      setLoading(false);
    }, (error) => {
      console.log(error);
      setError('Cound not fetch data');
      setLoading(false);
    });

    return () => unsubscribe();

  }, [c, q, o])

  return { documents, error, loading };
};