import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useDocument } from '../../hooks/useDocument';
// import { useCollection } from '../../hooks/useCollection';
import VisitStatusIcon from '../Icons/VisitStatusIcon';
import { getVisitStatus } from '../../models/Ticket';

const DateTimeView = ({ visit }) => {
  const getMonth = (date) => {
    const month = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
    const i = date.getMonth();

    return month[i];
  }

  return (
  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      height: 100,
      width: 100,
      background: '#FAFAFA',
      alignItems: 'center',
    }}
    variant="outlined"
  >
    {!visit.details.schedule && !visit.details.dispatch && (
      <Typography variant="body1" textAlign='center'>Not Scheduled Yet</Typography>
    )}
    {visit.details.schedule &&  (
      <>
        <Typography >{getMonth(visit.details.schedule.start.toDate())}</Typography>
        <Typography component="p" variant="h4">
          {visit.details.schedule.start.toDate().getDate()}
        </Typography>
      </>
    )}
    {!visit.details.schedule && visit.details.dispatch &&  (
      <>
        <Typography >{getMonth(visit.details.dispatch.timestamp.toDate())}</Typography>
        <Typography component="p" variant="h4">
          {visit.details.dispatch.timestamp.toDate().getDate()}
        </Typography>
      </>
    )}
  </Paper>
  {visit.details.schedule && <div style={{ minWidth: 70 }}>
    <Typography sx={{ fontSize: 14, fontWeight: "bold" }} >
      { visit.details.schedule.start.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}) }
    </Typography>
    <Typography textAlign='center'>-</Typography>
    <Typography sx={{ fontSize: 14, fontWeight: "bold" }} >
      { visit.details.schedule.end.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}) }
    </Typography>
  </div>}
  {!visit.details.schedule && visit.details.dispatch &&  (
    <Typography sx={{ fontSize: 14, fontWeight: "bold" }} >
      { visit.details.dispatch.timestamp.toDate().toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}) }
    </Typography>
  )}
  </Stack>
  )
}

const AssignedTechnicians = ({ tech, visit }) => {
  return <Stack>
    <Typography sx={{ fontSize: 14, fontWeight: "bold" }} >
      Technicians:
    </Typography>
    <Typography sx={{ fontSize: 14} } gutterBottom >
      { `${tech.person.firstName} ${tech.person.lastName}` }
    </Typography>
    {visit.details.checkOut && (
      <>
      <Divider />
      <Typography sx={{ fontSize: 14, fontWeight: "bold" }} >
        Completion Notes:
      </Typography>
      <Typography sx={{ fontSize: 14 }} >
        {visit.details.checkOut.description}
      </Typography>
      </>
    )}
  </Stack>
}

const VisitStatus = ({ status }) => {
  return <Stack alignItems='center'>
    <VisitStatusIcon status={status} />
    <Typography sx={{ fontSize: 14 }}>{getVisitStatus(status).name}</Typography>
  </Stack>
}

const VisitDetails = ({ visit }) => {
  // const technicianIds = visit.details.technicians.map(tech => tech.id);
  // const { documents: techs } = useCollection('users', ['id', 'in', technicianIds]);
  const { document: tech } = useDocument('users', visit.details.technicians[0].id);

  return <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' sx={{ overflow: 'auto' }}>
    {tech && <>
      <DateTimeView visit={visit} />
      <AssignedTechnicians tech={tech} visit={visit} />
      <VisitStatus status={visit.details.status} />
    </>
    }
  </Stack>
}

const VisitCard = ({ visit }) => {
  return <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 1,
    }}
  >
    <VisitDetails visit={visit} />
  </Paper>
}

export default VisitCard;