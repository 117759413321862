import { useState } from "react";
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import AttachmentItem from "../Attachments/AttachementItem";
import List from '@mui/material/List';

const UploadAttachmentsForm = (props) => {
  const {
    formField: { attachments },
    setFieldValue
  } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSelectFiles = (event) => {
    if (event.currentTarget.files?.length) {
      const attachmentArray = []
      for (let ind = 0; ind < event.currentTarget.files.length; ind++) {
        attachmentArray[ind] = event.currentTarget.files[ind]
      }

      setSelectedFiles((prevAttachments) => [...prevAttachments, ...attachmentArray])
      setFieldValue("attachments", [...selectedFiles, ...attachmentArray])
    }
  }

  const removeFile = (attachmentName) => {
    setSelectedFiles((prevAttachments) => {
      const updatedAttachments = [...prevAttachments].filter((attachment) => attachment.name !== attachmentName)
      return updatedAttachments
    })
  }


  return <div>
    <div style={{ marginTop: '1rem' }}>{attachments.label}</div>
    <Box sx={{ width: '100%' }}>
      <List>
        {selectedFiles.map((attachment, index) => {
          return <AttachmentItem 
            key={`attachment-${index}`} 
            attachment={attachment} 
            deleteAttachment={removeFile} 
            ableToDownload={false}
          />
        })}
      </List>
    </Box>
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      Upload files
      <input
        type="file" 
        name={attachments.name}
        multiple
        onChange={handleSelectFiles} 
        hidden 
      />
    </Button>
  </div>
}

export default UploadAttachmentsForm;