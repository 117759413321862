import * as Yup from 'yup';
import createTicketFormModel from './TicketFormModel';

const {
  formField: {
    location,
    area,
    description,
    nte,
    category,
    priority,
    type,
  }
} = createTicketFormModel;

const validationSchema = [
  Yup.object().shape({
    [location.name]: Yup.string().required(`${location.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [area.name]: Yup.string().required(`${area.requiredErrorMsg}`),
    [description.name]: Yup.string().required(`${description.requiredErrorMsg}`),
    [nte.name]: Yup.number().min(150, `${nte.invalidErrorMsg}`),
    [category.name]: Yup.array().of(Yup.number()).min(`${category.min}`, `${category.requiredErrorMsg}`),
    [priority.name]: Yup.number().required(`${priority.requiredErrorMsg}`),
    [type.name]: Yup.number().required(`${type.requiredErrorMsg}`),
  }), 
]

export default validationSchema;