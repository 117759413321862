import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  TextField,
  Tabs,
  Tab,
  AppBar,
  Typography,
  Input,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextMask } from '../../components/TextMask/TextMask';
import { useLogin } from '../../hooks/useLogin';
import './Login.scss';

const LoginPage = () => {
  const [selectedTab, setSelectedTab] = useState('email');
  const [passwordlessSignIn, setPasswordlessSignIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [linkCodeSent, setLinkCodeSent] = useState(false);

  const navigate = useNavigate();

  const {
    setUpRecaptcha,
    loginWithEmailAndPassword,
    sendEmailLink,
    loginWithPhoneNumber,
    error,
    isPending
  } = useLogin();

  useEffect(() => {
    setUpRecaptcha('signInButton');
  }, [setUpRecaptcha]);

  const handleSubmit = async () => {
    if (selectedTab === 'email' && !passwordlessSignIn){
      loginWithEmailAndPassword(userEmail, password);
    } else if (selectedTab === 'email' && passwordlessSignIn){
      sendEmailLink(userEmail);
      setLinkCodeSent(true);
    } else if (selectedTab === 'phoneNumber'){
      await loginWithPhoneNumber(phoneNumber);
      setLinkCodeSent(true);
      navigate('/confirm/phone')
    }
  }

  const onChangeHandler = (event, newValue) => {
    const  { currentTarget } = event;
    const { name, value } = currentTarget;

    if (name === 'email') {
      setUserEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'phoneNumber') {
      setPhoneNumber(value);
    } else if (name === 'passwordless') {
      setPasswordlessSignIn(passwordlessSignIn => !passwordlessSignIn);
    } else if (newValue) {
      setSelectedTab(newValue);
    }

  }

  let buttonLabel;
  if (selectedTab === 'email' && passwordlessSignIn) {
    buttonLabel = 'Send Link';
  } else if (selectedTab === 'email' && !passwordlessSignIn) {
    buttonLabel = 'Login';
  } else {
    buttonLabel = 'Send Code';
  };

  return (
    <div className="login">
      <img src={process.env.REACT_APP_IMG_LINK} className='login__logo' alt='company logo' />

      {error && (
        <Alert className="login__alert" severity="error">
          {error}
        </Alert>
      )}

      {selectedTab === 'email' && linkCodeSent && (
        <Alert className="login__alert" severity="success">
          Check your email to complete login!
        </Alert>
      )}

      {selectedTab === 'phoneNumber' && linkCodeSent && (
        <Alert className="login__alert" severity="success">
          SMS Successfully Sent!
        </Alert>
      )}

      <Typography variant='h5' className="login__header">
        Welcome to ServNology! Please login.
      </Typography>

      <AppBar position="static" className='login__tabs'>
        <Tabs value={selectedTab} name="signInType" onChange={onChangeHandler} aria-label="Log-In Tabs" variant="fullWidth">
          <Tab label="Email" value='email' id="lala" />
          <Tab label="Phone Number" value='phoneNumber' />
        </Tabs>
      </AppBar>

      <div className="login__selected-tab">
        {selectedTab === 'email' && (
          <div>
            <TextField
              className="login__email"
              onChange={onChangeHandler}
              placeholder="Enter email."
              label="Email"
              name="email"
              variant="outlined"
              value={userEmail}
            />
            
            {!passwordlessSignIn && (
              <TextField
                className="login__password"
                onChange={onChangeHandler}
                placeholder="Enter password."
                label="Password"
                name="password"
                type="password"
                variant="outlined"
                value={password}
              />
            )}

            <FormGroup className='login__passwordless-check'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={passwordlessSignIn}
                    onChange={onChangeHandler}
                    name="passwordless"
                    color="primary" 
                  />
                }
                label="Forget Password? Send me a link!"
              />
            </FormGroup>
          </div>
        )}

        {selectedTab === 'phoneNumber' && (
          <div className="login__phone-number">
            <InputLabel htmlFor="formatted-text-mask-input">
              Phone Number
            </InputLabel>
            <Input
              value={phoneNumber}
              onChange={onChangeHandler}
              name="phoneNumber"
              id="formatted-text-mask-input"
              inputComponent={TextMask}
            />
          </div>
        )}
      </div>

      <LoadingButton
        loading={isPending}
        id="signInButton"
        variant="contained"
        onClick={handleSubmit}
        disabled={linkCodeSent}
      >
        {buttonLabel}
      </LoadingButton>
    </div>
  );
}

export default LoginPage;
