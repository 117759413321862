import { doc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';

export const useFunctions = () => {

  const getTicketId = () => {
    const docsRef = doc(collection(db, 'ticket'));
    return docsRef.id;
  };

  return {
    getTicketId,
  }
};
