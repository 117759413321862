import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TicketStatusBar from '../../components/DashboardContent/TicketStatusBar';
import DashboardActions from '../../components/DashboardContent/DashboardActions';
import TicketList from '../../components/DashboardContent/TicketList';

const DashboardPage = () => {
  return (
    <Grid container spacing={3}>
      {/* Ticket Statuses */}
      {/* <Grid item xs={12} md={7} lg={8}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 200,
          }}
        >
          <TicketStatusBar />
        </Paper>
      </Grid> */}
      {/* Actions */}
      {/* <Grid item xs={12} md={5} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 200,
          }}
        >
          <DashboardActions />
        </Paper>
      </Grid> */}
      {/* Tickets */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <TicketList />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default DashboardPage;