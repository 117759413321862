import React from 'react';
import { Typography, Grid } from '@mui/material';
import { getCategory, getPriority, getTicketType } from '../../models/Ticket';

const TicketDetails = (props) => {
  const { formValues } = props;
  const { area, description, nte, category, priority, type } = formValues;

  const ticketType = getTicketType(type);
  const ticketPriority = getPriority(priority);
  const categories = getCategory(category);

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom >
        Ticket Details
      </Typography>
      <Typography gutterBottom>{`Ticket Type: ${ticketType.name}`}</Typography>
      <Typography gutterBottom>{`Priority: ${ticketPriority.name}`}</Typography>
      <Typography gutterBottom>{`Categories: ${categories.map(cat => ` ${cat.name}`)}`}</Typography>
      {type === 1 &&<Typography gutterBottom>{`NTE: $ ${nte}`}</Typography>}
      <Typography gutterBottom>{`Service Area: ${area}`}</Typography>
      <Typography gutterBottom>{`Service Description: ${description}`}</Typography>
    </Grid>
  );
}

export default TicketDetails;