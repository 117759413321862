import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useStorage } from "../../hooks/useStorage";
import { useEffect, useState } from "react";
import AttachmentItem from "./AttachementItem";

const TicketAttachments = ({ ticket }) => {

  const { getTicketAttachments, downloadAttachment } = useStorage();

  const [attachments, setAttachments] = useState();

  useEffect(() => {
    getTicketAttachments(ticket.client.id, ticket.location.id, ticket.id).then(res => {
      setAttachments(res)
    })
  }, [ticket])

  const downloadAttachmentFile = async (fileName) => {
    await downloadAttachment(ticket.client.id, ticket.location.id, ticket.id, fileName)
  }

  return (
    <>
      <Typography variant='overline'>Attachments</Typography>
      <List>
        {attachments?.map((attachment, ind) => (
          <Paper
            sx={{
              marginBottom: 1
            }}
            key={`attach-${attachment.id}-${ind}`}
          >
            <AttachmentItem 
              attachment={attachment} 
              ableToDownload={true} 
              downloadAttachment={()=>{downloadAttachmentFile(attachment.name)}} 
            />
          </Paper>
        ))} 
      </List>
    </>
  )
}

export default TicketAttachments;