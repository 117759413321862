import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function FilterAutocomplete(props) {
  const { label, options, value, setValue } = props;

  const formattedOptions = options.map(option => {
    return { label: option.shortName ? option.shortName : option.name, id: option.id }
  })

  return (
    <Autocomplete
      disablePortal
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange ={(event, newValue) => {
        setValue(newValue)
      }}
      options={formattedOptions}
      sx={{ width: 300, m: 1 }}
      renderInput={(params) => <TextField {...params} size="small" label={label} />}
    />
  );
}