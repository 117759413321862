import { Typography, Paper } from "@mui/material"

const Note = ({ ticketNote }) => {
  return (
    <Paper
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 1
      }}
    >
      <Typography variant='caption'>
        {ticketNote.metadata?.created?.timestamp?.toDate().toLocaleString('en-US')}
      </Typography>
      <Typography variant='overline'>{ticketNote.details?.title}</Typography>
      <Typography variant='caption'>{ticketNote.details?.description}</Typography>
    </Paper>
  )
}

export default Note;