import { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { GoogleMapsAutocompleteField } from '../../FormFields/index';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useFirestore } from '../../../hooks/useFirestore';

const addrDetails = {
  name: '',
  businessName: '',
  number: '',
};

const NewLocationForm = ({ closeModal }) => {
  const [enteredAddress, setEnteredAddress] = useState(false);
  const [address, setAddress] = useState(null);
  const [detailedAddrValue, setDetailedAddrValue] = useState(null);
  const [addressDetails, setAddressDetails] = useState(addrDetails);
  const [streetAddr2, setStreetAddr2] = useState('');

  const { user } = useAuthContext();
  const { addDocument } = useFirestore('locationFunctions-createNewLocation');

  const buttonHandler = () => {
    setEnteredAddress(state => !state);
  };

  const addLocationHandler = () => {
    const addressComponents = detailedAddrValue.formatted_address.split(',');
    const stateAndZip = addressComponents[2].split(' ');
    const newLocationData = {
      details: addressDetails,
      address: {
        streetAddress1: addressComponents[0],
        streetAddress2: streetAddr2,
        city: addressComponents[1],
        state: stateAndZip[1],
        postalCode: stateAndZip[2],
        country: addressComponents[3]
      },
      coordinates: {
        latitude: detailedAddrValue.geometry.location.lat(),
        longitude: detailedAddrValue.geometry.location.lng(),
      },
      client: {
        // id: user.company.id
        id: '68ZKnM8xmpQVUCHEK894' // for LBRM demo
      },
      //NOTE: will not work if an admin
      company: {
        id: user.company.metadata.owner.id
      }
    }
    addDocument(newLocationData);
    closeModal();
  }

  const onChangeHandler = (event) => {
    const  { currentTarget } = event;
    const { name, value } = currentTarget;

    if (name === 'locationName') {
      setAddressDetails(prevState => ({
        ...prevState,
        name: value, 
      }))
    } else if (name === 'storeNumber') {
      setAddressDetails(prevState => ({
        ...prevState,
        number: value, 
      }))
    } else if (name === 'busName') {
      setAddressDetails(prevState => ({
        ...prevState,
        businessName: value, 
      }))
    } else if (name === 'streetAddr2') {
      setStreetAddr2(value);
    } 
  }

  return <>
    <DialogTitle>Add New Location</DialogTitle>
    <DialogContent sx={{ margin: 'auto' }}>
      {!enteredAddress && (<>
        <DialogContentText>
          Please enter the address for the new location.
        </DialogContentText>
        
        <GoogleMapsAutocompleteField 
          value={address}
          setValue={setAddress}
          setDetailedValue={setDetailedAddrValue}
        />
      </>)}

      {enteredAddress && <div>
        <DialogContentText sx={{ paddingBottom: '15px' }}>
          Please enter the details for the new location.
        </DialogContentText>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <TextField 
              name="locationName"
              label="Location Name"
              value={addressDetails.name}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
              name="storeNumber"
              label="Store Number"
              value={addressDetails.number}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
              name="busName"
              label="Business Name"
              value={addressDetails.businessName}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              disabled
              fullWidth
              name="streetAddr1"
              label="Street Address 1"
              defaultValue={address.structured_formatting.main_text}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
            fullWidth
              name="streetAddr2"
              label="Street Address 2"
              value={streetAddr2}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              disabled
              fullWidth
              name="cityStateZip"
              label="City, State and Zip Code"
              defaultValue={address.structured_formatting.secondary_text}
            />
          </Grid>
        </Grid>
      </div>}
    </DialogContent>
    <DialogActions>
      <Button 
        onClick={ enteredAddress ? buttonHandler : closeModal } 
        color="error">
          {enteredAddress ? 'Back' : 'Cancel'}
      </Button>
      <Button 
        onClick={enteredAddress ? addLocationHandler : buttonHandler } 
        variant="contained" 
        disableElevation>
          {enteredAddress ? 'Add' : 'Next'}
      </Button>
    </DialogActions>
  </>
}

export default NewLocationForm;