import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const SelectField = (props) => {
  const { label, data, multiple, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  if (!multiple) {
    return (
      <FormControl {...rest} error={isError}>
        <InputLabel>{label}</InputLabel>
        <Select {...field} value={selectedValue ? selectedValue : ''} label={label}>
          {data.map((item) => (
            <MenuItem key={item.id} value={item.id} >
              {item.name}
              {item.details && item.details.name}
              {item.address ? ` - ${item.address.streetAddress1} ${item.address.city}, ${item.address.state}` : ''}
            </MenuItem>
          ))}
        </Select>
        {_renderHelperText()}
      </FormControl>
    );
  }

  if (multiple) {
    return (
      <FormControl {...rest} error={isError}>
          <InputLabel>{label}</InputLabel>
          <Select {...field} value={selectedValue} label={label} multiple>
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id} >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {_renderHelperText()}
        </FormControl>
    )
  }
}

SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectField;