import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ticketStatus } from '../../models/Ticket';

const StatusIcon = ({ status }) => {
  return (
    <Stack alignItems="center" maxWidth={75} spacing={1}>
      <Stack direction="row" spacing={0.5}>
        <Chip sx={{height: '1.5rem', width: '0.5rem', marginRight: '0.25rem', background: status === 0 ? "#BB2F2F" : "#FFCFCF"}} />
        <Chip sx={{height: '1.5rem', width: '0.5rem', marginRight: '0.25rem', background: status === 1 ? "#278727" : "#D7FCCB"}}/>
        <Chip sx={{height: '1.5rem', width: '0.5rem', marginRight: '0.25rem', background: status === 2 ? "#2B4C97" : "#E5E9FF"}}/>
        <Chip sx={{height: '1.5rem', width: '0.5rem', marginRight: '0.25rem', background: status === 3 ? "#6E349B" : "#F3E7FE"}}/>
      </Stack>
      <Stack direction="row">
        <div style={{ textAlign: "center", fontSize: 14 }}>{ticketStatus[status].name}</div>
      </Stack>
    </Stack>
  )
};

export default StatusIcon;