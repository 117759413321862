import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export const TextMask = forwardRef(function TextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+1 (#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ currentTarget: { name: props.name, value } })}
      overwrite
    />
  );
});