import './EmbeddedGoogleMaps.scss';
const { REACT_APP_MAPS_API_KEY } = process.env;

const EmbeddedGoogleMaps = (props) => {
  const {
    h: height,
    w: width,
    streetAddress,
    city,
    state,
  } = props;

  const address = `${streetAddress.replace(/\s/g, '+')}+${city}+${state}`

  const url = `https://www.google.com/maps/embed/v1/place?key=${REACT_APP_MAPS_API_KEY}&q=${address}`

  return <div className='iframe-wrapper' style={{ maxWidth: 475, maxHeight: 250 }}> 
    <iframe
      title="Embedded Google Map"
      width={width < 200 ? 450 : width}
      height={height < 200 ? 250 : height}
      frameBorder="0" 
      style={{ border: 0 }}
      referrerPolicy="no-referrer-when-downgrade"
      src={url}
      allowFullScreen>
    </iframe>
  </div>
}

export default EmbeddedGoogleMaps;