import { ref, uploadBytes, listAll, getBlob } from 'firebase/storage'
import { storage } from '../firebase/config'

const ticketAttachmentsPath = 'ticket/attachment/'
export const useStorage = () => {
  const uploadTicketAttachments = async (company, location, ticket, file, user) => {
    let attachmentPath = `${ticketAttachmentsPath}${company}/${location}/${ticket}/${file.name}`
    const attachmentsRef = ref(storage, attachmentPath);

    let generic_type = 'file'
    if (file.type === 'image/jpeg') {
      generic_type = 'image'
    }

    attachmentPath = attachmentPath + '/' + generic_type

    const metadata = {
      customMetadata: {
        'uploaded_by_id': user,
        'generic_type': generic_type,
      }
    }
    
    try {
      await uploadBytes(attachmentsRef, file, metadata)
      console.log('Uploaded a blob or file!');
    } catch (err) {
      console.log(err)
    }
  }

  const getTicketAttachments = async (company, location, ticket) => {
    // Create a reference under which you want to list
    const listRef = ref(storage, `${ticketAttachmentsPath}${company}/${location}/${ticket}`);

    let ticketAttachments = []

    // Find all the prefixes and items.
    try {
      const attachmentItems = await listAll(listRef)
      ticketAttachments = attachmentItems.items.map((itemRef) => itemRef)
    } catch (err) {
      console.log(err)
    }
      
    return ticketAttachments
  }

  const downloadAttachment = async (company, location, ticket, file) => {
    // Create a reference with an initial file path and name
    const pathReference = ref(storage, `${ticketAttachmentsPath}${company}/${location}/${ticket}/${file}`)
    
    try {
      const blob = await getBlob(pathReference)
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${file}`
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    uploadTicketAttachments,
    getTicketAttachments,
    downloadAttachment
  }

}