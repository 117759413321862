import { useState } from 'react';
import { useLogin } from '../../hooks/useLogin';
import { useParams } from 'react-router-dom';

import {
  Alert,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import './Login.scss';

const ConfirmPage = () => {
  const { 
    loginWithEmailLink,
    confirmPhoneSignIn, 
    error,
    isPending, 
  } = useLogin();

  const params = useParams();
  const { type } = params;

  const [userEmail, setUserEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const onChangeHandler = (event) => {
    const  { currentTarget } = event;
    const { name, value } = currentTarget;

    if (name === 'email') {
      setUserEmail(value);
    } else if (name === 'code') {
      setConfirmationCode(value);
    }
  }

  const onSubmitHandler = () => {
    if (type === 'email') {
      loginWithEmailLink(userEmail);
    } else if (type === 'phone') {
      confirmPhoneSignIn(confirmationCode);
    }
  };

  return (
    <div className="login">
      <img src={process.env.REACT_APP_IMG_LINK} className='login__logo' alt='company logo' />

      <Typography variant='h5' className="login__header">
        {type === 'phone' ? 'Please enter confirmaiton code.' : 'Please confirm email.'}
      </Typography>

      {error && (
        <Alert className="login__alert" severity="error">{error}</Alert>
      )}

      {type === 'email' && <div className="login__selected-tab">
        <div>
          <TextField
            className="login__email"
            onChange={onChangeHandler}
            placeholder="Enter email."
            label="Email"
            name="email"
            variant="outlined"
            value={userEmail}
          />
        </div>
      </div>}

      {type === 'phone' && <div className="login__selected-tab">
         <div>
           <TextField
            className="login__email"
            onChange={onChangeHandler}
            placeholder="Enter confirmation code."
            label="Confirmation Code"
            name="code"
            variant="outlined"
            value={confirmationCode}
          />
        </div>
      </div>}

      <LoadingButton
        id="signInButton-2"
        variant="contained"
        loading={isPending}
        onClick={onSubmitHandler}
      >
        {type === 'phone' ? 'Confirm Code' : 'Confirm Email'}
      </LoadingButton>
    </div>
  );
};

export default ConfirmPage;
